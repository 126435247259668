import Head from 'next/head'
import Link from 'next/link'

export default function Custom404() {
  return (
    <>
      <Head>
        <title>みんなのニュースレター</title>
        <meta name="robots" content="noindex" />
      </Head>

      <main className="max-w-2xl mx-auto px-2 md:px-0 ">
        <div className="pt-20 md:pt-40 pb-20">
        <Link href={process.env.NEXT_PUBLIC_FRONT_URL}>
          <img
            src="https://static-files.pages.dev/m-newsletter/images/mail.svg"
            className="mx-auto w-16"
            alt='mail'
          />
          <img
            src="https://static-files.pages.dev/m-newsletter/images/title.webp"
            className="mx-auto w-80"
            alt="みんなのニュースレター"
          />
          </Link>
        </div>
        <div className="leading-loose mb-8 text-center px-4">
          <h1>404 - ページが見つかりません</h1>
        </div>
      </main>
    </>
  );
}
